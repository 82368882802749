/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-mutation */
import React from 'react'
import _ from 'lodash'
import i18next from 'i18next'
import {handleCloseWeb, navigateToElectricityRate, popupFromIframe} from 'lib/common'
import {HTTPS_API_STATUS} from 'lib/services/httpRequest'
import {setNoExportCredit} from 'lib/services/noExportCreditAPI'
import {BATTERY_GRID_MODE, DIALOG_TYPE, OMST_STATUS, PROFILE_TYPE, SOURCE_TYPE, TOAST_TYPE} from 'lib/utils/constants'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'
import Icon from 'lib/utils/icon'
import {
  hasSystemCard,
  isDTEnabledSite,
  isDTSupportedSite,
  isCountryUS,
  siteHasPv,
  systemWithEnchargeAndGridAgnostic,
  isPVOnlySite,
  isCountryDE,
  IQGWScheduleSupportWithSavingsMode,
  isTouSelected,
  isILNEMSite,
  isNem3TariffSupported,
  showDTQuestion,
  batteryGridMode,
} from 'lib/utils/siteDetails'
import {isSource} from 'lib/utils/utility'
import Analytics from 'lib/utils/analytics'

export function checkTouAndNemBuybackExportPlan(details) {
  return details.isTariffTou && details.buybackExportPlan === 'nem'
}

export function checkDtSiteHasTouAndNonNemTariff(details) {
  return (
    !isFeatureSupported(FEATURE_KEYS.SAVINGS_MODE_CUSTOM_PEAK_SCHEDULE) &&
    details.isTariffTou &&
    details.buybackExportPlan !== 'nem'
  )
}

export function checkDTGermanyAndNullBuybackExportPlan(details) {
  return isDTSupportedSite() && isCountryDE() && _.isNull(details.buybackExportPlan)
}

function getProfileSwitchAlertDetails(details) {
  if (isDTSupportedSite()) {
    return getDTAlertDescription(details)
  }
  if (isILNEMSite()) {
    return getILNEMTariffAltDescription()
  }
  return getTouAlertDescription(details)
}

export function checkDtPvOnlySiteHasTouAndNoTarrif(details) {
  return details.isTariffTou && _.isNull(details.buybackExportPlan)
}

export function checkTouAndNullBuybackExportPlan(details) {
  return (
    details.isTariffTou &&
    _.isNull(details.buybackExportPlan) &&
    isFeatureSupported(FEATURE_KEYS.SAVINGS_MODE_CUSTOM_PEAK_SCHEDULE)
  )
}

export function checkFalseTouAndNullBuybackExportPlan(details) {
  return (
    details.isTariffTou === false &&
    _.isNull(details.buybackExportPlan) &&
    isFeatureSupported(FEATURE_KEYS.SAVINGS_MODE_CUSTOM_PEAK_SCHEDULE)
  )
}

export function checkDtTariffs(details) {
  const isDTEnabled = isDTEnabledSite()
  const {isTariffTou, buybackExportPlan: hasBuybackPlan} = details

  if (!isDTSupportedSite()) return true

  if (showDTQuestion()) {
    if (isPVOnlySite()) {
      if (hasSystemCard()) return (isDTEnabled || isTariffTou) && !_.isNull(hasBuybackPlan)
      if (isCountryDE()) return isDTEnabled && !_.isNull(hasBuybackPlan)
      return isDTEnabled
    }
    if (isCountryDE()) return (isDTEnabled || isTariffTou) && !_.isNull(hasBuybackPlan)
    return isDTEnabled || (isTariffTou && !_.isNull(hasBuybackPlan))
  } else {
    // DT is not available in France
    return isTariffTou && !_.isNull(hasBuybackPlan)
  }
}

export function checkTouAndBuybackExportPlan(details) {
  if (isDTSupportedSite() || isILNEMSite()) return false

  if (isFeatureSupported(FEATURE_KEYS.SAVINGS_MODE_CUSTOM_PEAK_SCHEDULE)) {
    return (
      details.isTariffTou === false ||
      checkTouAndNullBuybackExportPlan(details) ||
      checkFalseTouAndNullBuybackExportPlan(details)
    )
  }

  return !checkTouAndNemBuybackExportPlan(details)
}

export function checkILNEMTariff() {
  if (!isILNEMSite()) return true
  return isNem3TariffSupported()
}

// Savings Profile :: Get Description
export function getSavingsProfileDescription() {
  let desc = i18next.t('bp31')

  if (!siteHasPv()) {
    desc = i18next.t('bp32')
  }

  if (hasSystemCard()) {
    desc += ` ${i18next.t('bp117')}`
  }

  return desc
}

// Savings Profile :: Get TOU Alert Description
export function getTouAlertDescription(details) {
  let desc = (
    <>
      {isCountryUS() ? i18next.t('bp40') : i18next.t('bp273')}
      <br />
      <br />
      {isCountryUS() ? i18next.t('bp41') : i18next.t('bp274')}
    </>
  )

  if (isFeatureSupported(FEATURE_KEYS.SAVINGS_MODE_CUSTOM_PEAK_SCHEDULE)) {
    desc = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp451') : i18next.t('bp42')
  }

  if (checkTouAndNullBuybackExportPlan(details)) {
    desc = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp452') : i18next.t('bp43')
  }

  return (
    <div className="e_dialog__body__wrapper">
      <Icon src="alert" />
      <div className="e_dialog__content">{desc}</div>
    </div>
  )
}

// Savings Profile :: Get IL NEM Site tariff Alert Description
function getILNEMTariffAltDescription() {
  return (
    <div className="e_dialog__body__wrapper">
      <Icon src="alert" />
      <div className="e_dialog__content">{i18next.t('bp497')}</div>
    </div>
  )
}

// Savings Profile :: Get DT Alert Description
export function getDTAlertDescription(details) {
  const isAIOptiSite = IQGWScheduleSupportWithSavingsMode()
  const isDE = isCountryDE()
  const isDTEnabled = isDTEnabledSite()
  const isTou = isTouSelected()
  const hasEvs = hasSystemCard()
  const hasBuybackPlan = !!details?.buybackExportPlan
  const isPVOnly = isPVOnlySite()

  const baseHeading1 = i18next.t(isAIOptiSite ? 'bp445' : 'bp420')
  const baseHeading2 = showDTQuestion()
    ? i18next.t(isAIOptiSite ? 'bp448' : 'bp421')
    : i18next.t(isAIOptiSite ? 'bp523' : 'bp522')
  const baseDesc = (
    <>
      {baseHeading1} <br /> <br /> {baseHeading2}
    </>
  )

  let desc

  if (showDTQuestion()) {
    if (isPVOnly) {
      if (!hasEvs && (!isDTEnabled || (isDE && !hasBuybackPlan))) {
        desc = (
          <>
            {i18next.t('bp475')} <br /> <br /> {i18next.t('bp476')}
          </>
        )
      } else if (!hasBuybackPlan) {
        desc = isDTEnabled || isTou ? i18next.t(isAIOptiSite ? 'bp474' : 'bp43') : baseDesc
      } else {
        desc = baseDesc
      }
    } else if (!hasBuybackPlan) {
      desc = isDTEnabled || isTou ? i18next.t('bp474') : baseDesc
    } else {
      desc = (isDTEnabled || isTou) && !hasBuybackPlan ? i18next.t(isAIOptiSite ? 'bp474' : 'bp43') : baseDesc
    }
  } else {
    // DT is not available in France
    desc = isTou && !hasBuybackPlan ? i18next.t('bp474') : baseDesc
  }

  return (
    <div className="e_dialog__body__wrapper">
      <Icon src="alert" />
      <div className="e_dialog__content">{desc}</div>
    </div>
  )
}

// ToU Alert :: onClick No Export Credit
const setTariffZeroExport = async args => {
  Analytics.sendClickEvent('No_Export_Savings', 'edit_profile')
  const {showLoader, setPdSlice, closeThisDialog, showToast} = args
  showLoader({show: true, noBg: true})
  closeThisDialog()

  // Set No Export Credit
  try {
    const res = await setNoExportCredit()
    if (res?.status === HTTPS_API_STATUS.SUCCESS) {
      showLoader({show: false})
      setPdSlice({loading: false, inProgress: null})
      showToast({
        type: TOAST_TYPE.SUCCESS,
        message: i18next.t('bp200'),
        autoClose: 3000,
      })
    } else {
      throw new Error('Error')
    }
  } catch (error) {
    console.error('[Error] setTariffZeroExport', error)

    showLoader({show: false})
    setPdSlice({loading: false, inProgress: null})
    showToast({
      type: TOAST_TYPE.ERROR,
      message: i18next.t('bp68'),
      autoClose: 3000,
    })
  }
}

// ToU Alert :: onClick Update Electricity Rate
const updateElectricityRate = () => {
  Analytics.sendClickEvent('Update_Electricity_Savings', 'edit_profile')
  handleCloseWeb()
  navigateToElectricityRate()
  popupFromIframe({displayFooter: false, dialogType: DIALOG_TYPE.CENTER})
}

// ToU Alert :: Details
export function getTouAlertDetails(args) {
  const {details, setPdSlice, closeThisDialog} = args
  const sgInfoDescription = getProfileSwitchAlertDetails(details)
  const obj = {
    className: `sp__tou-alert-popup e_dialog--button-w100 ${isDTSupportedSite() && 'dt-tou-alert'}`,
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content: sgInfoDescription,
    buttons: null,
  }

  function closeDialog() {
    Analytics.sendClickEvent('Cancel_Electricity_Savings', 'edit_profile')
    setPdSlice({loading: false, inProgress: null})
    closeThisDialog()
  }

  if (isSource(SOURCE_TYPE.ENHO)) {
    obj.buttons = [
      {
        value: i18next.t('bp44'),
        action: () => updateElectricityRate(),
        disabled: false,
        className: 'bp__ab--mobile-view-hr',
      },
      {
        value: i18next.t('bp99'),
        action: closeDialog,
        disabled: false,
        className: 'bp__ab--mobile-view-hr',
      },
    ]
  } else {
    obj.buttons = [
      {
        value: i18next.t('bp46'),
        action: closeDialog,
        disabled: false,
        className: 'bp__ab--mobile-view-hr',
      },
    ]
  }

  if (checkTouAndNullBuybackExportPlan(details) && !(isDTSupportedSite() && isCountryDE())) {
    obj.buttons.splice(1, 0, {
      value: i18next.t('bp45'),
      action: () => setTariffZeroExport(args),
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    })
  }

  return obj
}

export function getSavingsConfirmActivationDescription(popupText) {
  const text = i18next.t('bp201')
  return (
    <div className="sp__popup__wrapper">
      <div className="e_dialog__content">{popupText}</div>
      <div className="e_dialog__content">{text}</div>
    </div>
  )
}

export function getConfirmPopupDescription(batteryProfile) {
  let popUpText = i18next.t('bp169')
  const soc = batteryProfile.batteryBackupPercentage
  const eiStatus =
    batteryProfile.operationModeSubType === OMST_STATUS.PRIORITIZE_ENERGY ? i18next.t('bp7') : i18next.t('bp8')

  if (!systemWithEnchargeAndGridAgnostic()) {
    if (batteryProfile.profile === PROFILE_TYPE.COST_SAVINGS) {
      popUpText = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp477') : i18next.t('bp170', {eiStatus})
    } else if (batteryProfile.profile === PROFILE_TYPE.SELF_CONSUMPTION) {
      popUpText = i18next.t('bp171')
    }
  } else if (batteryProfile.profile === PROFILE_TYPE.COST_SAVINGS) {
    popUpText = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp471', {soc}) : i18next.t('bp172', {soc, eiStatus})
  } else if (batteryProfile.profile === PROFILE_TYPE.SELF_CONSUMPTION) {
    popUpText = i18next.t('bp173', {soc})
  }
  return popUpText
}

export function supportBatteryGridImport() {
  return [BATTERY_GRID_MODE.IMPORT_ONLY, BATTERY_GRID_MODE.IMPORT_EXPORT].includes(batteryGridMode())
}
