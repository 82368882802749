/* eslint-disable fp/no-mutation */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import i18next from 'i18next'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import Analytics from 'lib/utils/analytics'
import {
  ACTION_TYPE,
  DIALOG_TYPE,
  MOMENT_JS_FORMAT,
  SOURCE_TYPE,
  STORM_GUARD_STATUS,
  STORM_ALERT_STATUS,
} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'
import {getSiteTimezone,isSettingsLocked} from 'lib/utils/siteDetails'
import {isUserHasAccess} from 'lib/utils/userDetails'
import {isSource, isWeb} from 'lib/utils/utility'
import moment from 'moment-timezone'
import {IntractiveButton} from '../../atoms/IntractiveButton'
import {showToast} from '../../atoms/Toast'
import {
  getBtnText,
  getInfoDescription,
  getCAInfoDescription,
  getStormAlertStatus,
  getStormAlerts,
  setThisStormAlert,
  sortStormAlerts,
} from './saCore'
import {setAlertDetails, setSaValue, stormAlertSelector} from './saSlice'
import LockPopUp from '../LockPopUp'
import './styles.scss'

/* *********************** *********************** *********************** *
 * Storm Alerts
 * *********************** *********************** *********************** */

const StormAlerts = () => {
  const batteryProfile = useSelector(batteryProfileSelector)
  const stormGuard = useSelector(state => state.components.stormGuard)
  const {loading, getApiLoading, stormAlerts} = useSelector(stormAlertSelector)
  const {details} = batteryProfile

  const sgaTitle = i18next.t('bp379')

  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setThisAlertDetails = obj => dispatch(setAlertDetails({...obj}))
  const setSaSlice = obj => dispatch(setSaValue({...obj}))

  useEffect(() => {
    getStormAlerts({setSaSlice})
  }, [])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  // Get Storm Alert Info Content
  const getSaInfoDetails = () => {
    const saInfoDescription = getInfoDescription()
    const obj = {
      className: 'sa__info-popup',
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title: sgaTitle,
      content: saInfoDescription,
      buttons: null,
      onClickCloseIcon: closeThisDialog,
    }
    return obj
  }

  const getCAOptoutPopupDetails = (alertObj) => {
    const caInfoDescription = getCAInfoDescription(alertObj)
    const obj = {
      className: `sa__change-popup ca__optout-popup ${isWeb() ? 'ca__optout-popup__web' : ''}`,
      type: DIALOG_TYPE.CENTER,
      showDialog: true,
      showCloseIcon: false,
      title: i18next.t('bp526'),
      content: caInfoDescription,
      buttons: [
        {
          value: i18next.t('bp105'),
          action: closeThisDialog,
          disabled: false,
          className: 'ca__optout-popup__btn-no',
        },
        {
          value: i18next.t('bp104'),
          action: () => {
            setThisStormAlert({
              setSaSlice,
              setThisAlertDetails,
              showToast,
              obj: alertObj
            })
            closeThisDialog()
          },
          disabled: false,
          className: 'ca__optout-popup__btn-yes',
        },
      ],
    }
    return obj
  }

  // On Click Info Icon
  const onClickInfo = () => {
    const obj = getSaInfoDetails()
    showDialog(obj)
    Analytics.sendClickEvent('StormGuard_Alerts_info', 'StormGuard')
  }

  const isOptBtnDisabled = obj =>
    loading || stormGuard.loading

  /* *********************** *********************** *********************** *
   * Functions
   * *********************** *********************** *********************** */

  // On Click Opt Button
  async function onClickOptBtn(obj) {
    // Check User Access
    if (!isUserHasAccess()) {
      return
    }
    if(isSettingsLocked()){
      showDialog(LockPopUp(closeThisDialog))
      return 
    }

    // Set Storm Alert
    if (!(isOptBtnDisabled(obj) || (obj?.critical && obj?.status !== STORM_ALERT_STATUS.OPTED_OUT))) {
      Analytics.sendClickEvent(
        `SG_${obj.status === STORM_ALERT_STATUS.ACTIVE ? 'OptOut' : 'OptIn'}_${obj.name}`,
        'StormGuard'
      )
      setThisStormAlert({setSaSlice, setThisAlertDetails, showToast, obj})
    } else if (obj?.critical && obj?.status !== STORM_ALERT_STATUS.OPTED_OUT) {
      const optOutPopupObj = getCAOptoutPopupDetails(obj)
      showDialog(optOutPopupObj)
    }
  }

  /* *********************** *********************** *********************** *
   * Render Functions
   * *********************** *********************** *********************** */

  // Render : Storm Alert Card
  function renderStormAlert(obj) {
    const {name, source, status, startTime, endTime, isLoading} = obj
    const btnText = getBtnText({status})
    const saStatusDetails = getStormAlertStatus({status, loading: isLoading})
    const startTimeText = moment(startTime).tz(getSiteTimezone()).format(MOMENT_JS_FORMAT.DATE_MMM_DD_TIME_12H_AM_PM)
    const endTimeText = moment(endTime).tz(getSiteTimezone()).format(MOMENT_JS_FORMAT.DATE_MMM_DD_TIME_12H_AM_PM)
    let saStatus = null
    let saStatusClass = null

    if (saStatusDetails) {
      ;[saStatus, saStatusClass] = saStatusDetails
    }

    return (
      <div key={name} id={`sa-${name.toLowerCase().replace(/ /g, '-')}`} className="sa__item">
        <div className="atom-design--t1">
          <div className="com__ad__title">
            <div className="sa__item__title">{name}</div>
          </div>

          {source && (
            <div className="com__ad__value">
              <span className="sa__item__desc">{i18next.t('bp380')}:</span>
              <span className="sa__item__desc">{source}</span>
            </div>
          )}

          <div className="com__ad__action">
            <IntractiveButton
              className="bp__action-button bp__ab--round-corner bp__ab--outline bp__ab--auto-width"
              type={(isOptBtnDisabled(obj) || isSettingsLocked()) ? ACTION_TYPE.DISABLED : ACTION_TYPE.EDIT}
              label={btnText}
              disabled={false}
              loading={isLoading}
              loadingIcon="loaderV2"
              onClick={() => onClickOptBtn(obj)}
            />
          </div>
        </div>

        {saStatus && (
          <div className="sa__status-info">
            {saStatus && <div className={classNames('sa__status-text', saStatusClass)}>{saStatus}</div>}
          </div>
        )}

        {(startTime || endTime) && (
          <div className="sa__time-info">
            {startTime && (
              <div>
                <div className="sa__time-label">{i18next.t('bp383')}</div>
                <div className="sa__time-value">{startTimeText}</div>
              </div>
            )}

            {endTime && (
              <div>
                <div className="sa__time-label">{i18next.t('bp384')}</div>
                <div className="sa__time-value">{endTimeText}</div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  // Render : Storm Alerts Card List
  function renderStormAlerts() {
    return (
      <div className="sa__list">
        {stormAlerts?.length > 0 ? (
          _.map(sortStormAlerts(stormAlerts), obj => renderStormAlert(obj))
        ) : (
          <div className="sa__item sa__no-alert">
            <div className="com__ad__details">
              <div className="com__ad--title">{i18next.t('bp387')}</div>
            </div>
          </div>
        )}
      </div>
    )
  }

  // Render : Shimmer
  function renderShimmer() {
    return <div className="sa__shimmer bp__shimmer" />
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  // Hide Storm Alert Section
  if (details.stormGuardState === STORM_GUARD_STATUS.DISABLED || !isFeatureSupported(FEATURE_KEYS.STORM_GUARD_OPTOUT)) {
    return null
  }

  return (
    // Storm Alerts
    <section id="bp__storm-alerts" className="bp__storm-alerts">
      <div className="sa__wrapper">
        <div className="sa__heading">
          {sgaTitle}
          <div className="sa__info" onClick={onClickInfo} onKeyDown={() => {}} role="button" tabIndex="0">
            {isSource(SOURCE_TYPE.ITK) ? <Icon src="itkHelp" /> : <Icon src="info" />}
          </div>
        </div>

        {getApiLoading ? renderShimmer() : renderStormAlerts()}
      </div>
    </section>
  )
}

StormAlerts.propTypes = {}

StormAlerts.defaultProps = {}

export default StormAlerts
